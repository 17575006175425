// @ts-ignore
import { TrackingItems } from "@tef/openapi-client";
import { AddShippingInfoEvent } from "@mfe/tef-tracking-types";
import { AddShippingInfoDL } from "#/dataLayer";
import { mapFlags, sumItemPrices } from "../../utils";

export const mapAddShippingInfo = (evt: AddShippingInfoEvent, backendItems: TrackingItems): AddShippingInfoDL => {
    return {
        event: "eec_add_shipping_info",
        event_name: "add_shipping_info",
        eec_add_shipping_info: {
            category: "eec",
            action: "click - add shipping info",
            label: evt.label ?? "-",
        },
        ecommerce: {
            currency: "EUR",
            value: sumItemPrices(evt.offers, backendItems, evt.shippingCost),
            shipping_tier: evt.shippingMethod ?? "-",
            items: evt.offers.map((offer) => {
                return {
                    ...backendItems[offer.id],
                    quantity: 1,
                    index: offer.index + 1,
                    item_category2: offer.subCategory,
                    item_list_id: evt.mfeName,
                    item_list_name: evt.mfeName,
                    item_variant: offer.variant,
                    flag: mapFlags(offer.flags?.flag1, offer.flags?.flag2, offer.flags?.flag3),
                };
            }),
        },
    };
};
