import { NotificationDisplayEvent } from "@mfe/tef-tracking-types";
import { NotificationDisplayDL } from "#/dataLayer";

export const mapNotificationDisplay = (evt: NotificationDisplayEvent): NotificationDisplayDL => {
    const res: Partial<NotificationDisplayDL> = {
        event: `generic_display_notification_${evt.alertType}`,
        event_name: "display_notification",
    };

    res[`generic_display_notification_${evt.alertType}`] = {
        action: `display - ${evt.alertType} message`,
        category: `notification - ${evt.flowName ?? "na"}`,
        label: "-",
        message: evt.message,
        non_interaction: true,
        value: 0,
    };

    //FIXME: make really typesave
    return res as NotificationDisplayDL;
};
