import type { DataLayerValues } from "./types";
import { currentPi } from "../../package.json";

// globally ensure existence of datalayer
window.dataLayer = window.dataLayer || [];

export const dataLayerPush = (data: DataLayerValues, mfeName: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        data.eventCallback = () => {
            resolve();
        };
        window.dataLayer?.push({
            ...data,
            mfe_name: mfeName,
            partner_id: getPartnerId(data),
            lib_version: currentPi + ".0.0",
        });
    });
};

function getPartnerId(data: DataLayerValues): string {
    const tefQuoteId = getCookie("tef_quote_id");
    const partnerId = sessionStorage.getItem("tef:tracking:partner:id");
    const quoteId = sessionStorage.getItem("tef:tracking:partner:quote:id");

    if ("partner_id" in data && data.partner_id !== undefined) {
        return data.partner_id;
    }

    if (partnerId === null || tefQuoteId === null || quoteId === null || tefQuoteId !== quoteId) {
        return "-";
    }

    return partnerId;
}

function getCookie(name: string): string | null {
    const nameLenPlus = name.length + 1;
    return (
        document.cookie
            .split(";")
            .map((c) => c.trim())
            .filter((cookie) => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map((cookie) => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null
    );
}
