import { GenericFlowEvent } from "@mfe/tef-tracking-types";

export const mapCustomerCancellationFlow = (evt: GenericFlowEvent) => {
    const AdditionalData = {
        intention: evt.intention,
        cancellation_reason: evt.cancellation_reason ?? "-",
        deactivation_date: evt.deactivation_date,
        request_type: evt.request_type ?? "-",
        request_payout: evt.request_payout ?? "-",
    };
    return AdditionalData;
};
