import { GenericFlowEvent } from "@mfe/tef-tracking-types";
import { GenericFlowEventDL } from "#/dataLayer";
import { mapCustomerCancellationFlow } from "./genericFlowAdditonalData";
export const mapGenericFlowAdditonalData = (evt: GenericFlowEvent) =>{
    const flowName:string = `${evt.flowName}` || 'na';

    const mapFlowWithFunction:any = {
        "customer_cancellation": mapCustomerCancellationFlow(evt)
    }

    const prepareData = mapFlowWithFunction[`${flowName}`];

    return prepareData || {}
}
