import { FLOW_KEY, setupTrackingEventBus } from "#/bus";
import { registerTrackingEventListeners } from "#/eventListener";
import { dataLayerPush } from "#/dataLayer";
import { mapPageData } from "#/payloadMapper";
import { getPageData } from "./pageData";
import { Flow } from "@mfe/tef-tracking-types";

/**
 * to be called on lib initial load on AEM page
 */
const pushPageView = () => {
    const pageData = getPageData();
    if (pageData.flowName) {
        window.sessionStorage.setItem(FLOW_KEY, pageData.flowName);
    } else {
        pageData.flowName = (window.sessionStorage.getItem(FLOW_KEY) as Flow) || "na";
    }

    dataLayerPush(
        {
            event: "page_view",
            page: mapPageData(pageData),
        },
        "tracking-lib",
    );
};

if (!window.tefTracking) {
    registerTrackingEventListeners();
    setupTrackingEventBus();

    if (document.readyState === "complete" || document.readyState === "interactive") {
        // directly push page view when the document state is already completed
        pushPageView();
    } else {
        // otherwise register an event lister when the state changes
        document.onreadystatechange = () => {
            if (document.readyState === "complete") {
                pushPageView();
            }
        };
    }
}
